import _objectWithoutProperties from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "ariaLabel", "accordionData"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './Accordion.style';
import Heading from '../../atoms/Heading';
import RichText from '../../atoms/RichText/RichText';
import urlSlug from 'url-slug';
import Anchor from '../../atoms/Anchor';
import componentAnalytics from '../../../constants/analyticsConstants';
import AccordionMultiLocation from './AccordionMultiLocation';
var AccordionBlock = styled.section.withConfig({
  componentId: "sc-15z13q9-0"
})(["", ";"], styles);
var Accordion = function Accordion(_ref) {
  var className = _ref.className,
    ariaLabel = _ref.ariaLabel,
    accordionData = _ref.accordionData,
    others = _objectWithoutProperties(_ref, _excluded);
  var accordionContentCollection = accordionData.accordionContentCollection,
    description = accordionData.description,
    anchorLink = accordionData.anchorLink,
    sectionHeadline = accordionData.sectionHeadline,
    cta = accordionData.cta;
  var accordionType = accordionData.accordionType || 'Regular';
  var accordionContent = accordionContentCollection.items;
  var idVal = anchorLink ? anchorLink : sectionHeadline ? urlSlug(sectionHeadline) : '';
  var richTextEmbeddedAsset = description && description.links && description.links.assets.block || {};
  if (typeof window !== 'undefined') {
    require('details-polyfill');
  }
  return __jsx(AccordionBlock, {
    className: "nva-accordion ".concat(className)
  }, accordionType == 'Regular' && __jsx("div", {
    className: "container"
  }, __jsx("div", {
    className: "nva-accordion__blade accordion-blade"
  }, __jsx("div", {
    className: "nva-accordion__information accordion-information"
  }, sectionHeadline && __jsx(Heading, {
    HeadingType: "h2",
    className: "nva-accordion__heading accordion-heading",
    id: idVal
  }, sectionHeadline), description && description.json && __jsx(RichText, {
    className: "nva-accordion__description col-lg-8 col-sm-10 accordion-description",
    jsonContent: description.json,
    richTextEmbeddedAsset: richTextEmbeddedAsset
  }), cta && cta.url && cta.label && __jsx("div", {
    className: "nva-accordion__cta accordion-cta"
  }, __jsx(Anchor, {
    title: cta.label,
    "aria-label": cta.ariaLabel,
    to: cta.url,
    ctaBehavior: cta.behavior,
    hyperlinkType: cta.hyperlinkType,
    className: "nva-accordion__anchor secondary",
    styleType: "primary-anchor",
    "data-analytics-type": componentAnalytics.accordionBlade.analyticsType || '',
    "data-analytics-value": componentAnalytics.accordionBlade.analyticsValue || ''
  }, cta.label))), accordionContent.map(function (row, index) {
    var header = row.header,
      body = row.body;
    var accordionEmbeddedAsset = row.body.links && row.body.links.assets.block || {};
    return __jsx("details", {
      className: "nva-accordion__row accordion-row",
      key: row.sys.id
    }, __jsx("summary", {
      role: "button"
    }, __jsx(Heading, {
      HeadingType: "h3",
      className: "nva-accordion__row-heading"
    }, header)), __jsx(RichText, {
      jsonContent: body.json,
      richTextEmbeddedAsset: accordionEmbeddedAsset
    }));
  }))), accordionType == 'Multi-location-custom' && __jsx("div", {
    className: "nva-accordion__multi-location multi-location-accordion"
  }, accordionContent.map(function (row, index) {
    if (row && row.multiLocationContainer) {
      return __jsx(AccordionMultiLocation, {
        multiLocationData: row.multiLocationContainer,
        customName: row.header
      });
    }
  })));
};
Accordion.defaultProps = {
  className: '',
  ariaLabel: ''
};
export default styled(Accordion).withConfig({
  componentId: "sc-15z13q9-1"
})(["", ";"], styles);